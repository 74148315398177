import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import Cookies from "js-cookie";
import { headerRoutes } from "../../../router";
import Button from "../Button/Button";
import logo from '../../../assets/img/Global/logo.png';
import $ from "jquery";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const languages = [
  { value: "ru", label: "Ru" },
  { value: "en", label: "En" },
  { value: "es", label: "Es" },
  { value: "it", label: "It" },
  { value: "de", label: "De" },
  { value: "pl", label: "Pl" },
  { value: "no", label: "NO" },
  { value: "fi", label: "FI" },
  { value: "fr", label: "FR" },
  { value: "cs", label: "CS" },
];

const Header = () => {
  const headerRef = useRef(null);
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const [currentLang, setCurrentLang] = useState(Cookies.get("i18next"));
  const { pathname } = useLocation();

  useEffect(() => {
    let wdt;
    if (document.documentElement.clientWidth > 1220) {
      wdt = 2500;
    } else {
      wdt = 100;
    }
    if (pathname !== "/") {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
    window.addEventListener("scroll", (event) => {
      // eslint-disable-next-line
      if (pathname == "/") {
        if (window.pageYOffset > wdt) {
          $(headerRef.current).removeClass(classes.hideBg);
        } else {
          $(headerRef.current).addClass(classes.hideBg);
        }
      } else {
        $(headerRef.current).removeClass(classes.hideBg);
      }
    });
  }, [pathname]);

  useEffect(() => {
    setCurrentLang(Cookies.get("i18next"));
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  useEffect(() => {
    setBurgerOpened(false);
  }, [pathname]);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });
  return (
    <header
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link
              to="/"
              onClick={() => {
                $("body").removeClass("lock");
                setBurgerOpened(false);
              }}
            >
              <img src={logo} alt=''/>
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route) => (
              <Link
                onClick={() => {
                  $("body").removeClass("lock");
                }}
                key={route.id}
                to={route.path}
                className={`${route.path === pathname && classes.linkActive}`}
              >
                {t(route.text)}
              </Link>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => i18n.changeLanguage(e.value)}
                value={currentLang}
                placeholder="EN"
              />
            </div>
            <a href="https://user.llds-group.tech/login" className={classes.login}>
              {t("header_login")}
            </a>
            <div
              onClick={() => {
                setBurgerOpened((value) => !value);
                if ($("body").hasClass("lock")) {
                  $("body").removeClass("lock");
                } else {
                  $("body").addClass("lock");
                }
              }}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            ></div>
          </div>
        </div>
      </div>
      <div className={`${classes.mobileMenu} ${burgerOpened && classes.mobileMenuActive}`}>
        <div className="container">
          {headerRoutes.map((route) => (
            <Link
              key={route.id}
              to={route.path}
              className={`${route.path === pathname && classes.linkActive} ${classes.aLink}`}
              onClick={() => {
                $("body").removeClass("lock");
                setBurgerOpened(false);
              }}
            >
              {t(route.text)}
            </Link>
          ))}
          <div className={classes.btn}>
            <Button login="true">
              {t("header_login")}
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
