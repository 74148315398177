import React, { useEffect } from "react";
import Awards from "../../components/MainPage/Awards/Awards";
import Banner from "../../components/MainPage/Banner/Banner";
import InvestIn from "../../components/MainPage/InvestIn/InvestIn";
import OnlineTrading from "../../components/MainPage/OnlineTrading/OnlineTrading";
import OpenAnAccount from "../../components/MainPage/OpenAnAccount/OpenAnAccount";
import OurAdvantages from "../../components/MainPage/OurAdvantages/OurAdvantages";
import TheBest from "../../components/MainPage/TheBest/TheBest";
import TheMarkets from "../../components/MainPage/TheMarkets/TheMarkets";
import Newsletters from '../../components/BeginComponents/Bottom/Bottom';

const MainPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <Banner />
            <InvestIn />
            <OurAdvantages />
            <OnlineTrading />
            <TheBest />
            <TheMarkets />
            <div className="nwsl">
                <Newsletters />
            </div>
            <OpenAnAccount />
            <Awards />
        </div>
    );
};

export default MainPage;
