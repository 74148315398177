import React, { useState, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import arrowOrange from "../../../assets/img/BeginPage/2/morearrow.png";
import img1 from "../../../assets/img/BeginPage/2/1.png";
import img2 from "../../../assets/img/BeginPage/2/2.png";
import img3 from "../../../assets/img/BeginPage/2/3.png";
import img4 from "../../../assets/img/BeginPage/2/4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSwipeable } from "react-swipeable";
import "swiper/css";
import "swiper/swiper.min.css";
import "./Slider.scss";
import classes from "./Slider.module.scss";

const Slider = () => {
  const { t } = useTranslation();
  const [sliderPosLeft, setSliderPosLeft] = useState(0);
  const containerRef = useRef(null);
  const column1Ref = useRef(null);
  const paginationRef = useRef(null);
  const [countSlide, setCountSlide] = useState(0);
  let config = {
    trackMouse: true,
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      slideRight();
    },
    onSwipedRight: () => {
      slideLeft();
    },
    ...config,
  });
  const myRef = React.useRef();
  const refPassthrough = (el) => {
    handlers.ref(el);
    myRef.current = el;
  };

  const slideLeft = () => {
    let columnWidth = column1Ref.current.clientWidth;
    let nodeStyle = window.getComputedStyle(column1Ref.current);
    let slideMarginRight = nodeStyle.getPropertyValue("margin-right");
    var fix = /px/gi;
    var columnMarginRight = +slideMarginRight.replace(fix, "");
    let resSlidePos = +columnWidth + +columnMarginRight;

    if (window.innerWidth > 1420) {
      if (countSlide === 1) {
        setCountSlide(0);
        setSliderPosLeft(0);
      }
      if (countSlide === 2) {
        setCountSlide(1);
        setSliderPosLeft(-resSlidePos);
      }
    }
    if (window.innerWidth < 1421) {
      if (countSlide === 1) {
        setCountSlide(0);
        setSliderPosLeft(0);
      }
      if (countSlide === 2) {
        setCountSlide(1);
        setSliderPosLeft(-resSlidePos);
      }
      if (countSlide === 3) {
        setCountSlide(2);

        setSliderPosLeft(-resSlidePos + -resSlidePos);
      }
    }
  };
  const slideRight = () => {
    let containerWidth = containerRef.current.clientWidth;
    let columnWidth = column1Ref.current.clientWidth;
    let nodeStyle = window.getComputedStyle(column1Ref.current);
    let slideMarginRight = nodeStyle.getPropertyValue("margin-right");
    var fix = /px/gi;
    var columnMarginRight = +slideMarginRight.replace(fix, "");
    let resSlidePos = +columnWidth + +columnMarginRight;
    let sliderWidth = 4 * columnWidth + 3 * columnMarginRight;

    if (window.innerWidth > 1420) {
      if (countSlide === 0) {
        setCountSlide(1);
        setSliderPosLeft(sliderPosLeft - resSlidePos);
      }
      if (countSlide === 1) {
        setCountSlide(2);

        resSlidePos = containerWidth - sliderWidth;
        setSliderPosLeft(resSlidePos);
      }
    }

    if (window.innerWidth < 1421) {
      if (countSlide === 0) {
        setCountSlide(1);
        setSliderPosLeft(sliderPosLeft - resSlidePos);
      }
      if (countSlide === 1) {
        setCountSlide(2);
        setSliderPosLeft(sliderPosLeft - resSlidePos);
      }
      if (countSlide === 2) {
        setCountSlide(3);

        resSlidePos = containerWidth - sliderWidth;
        if (window.innerWidth < 1220) {
          resSlidePos -= 80;
        }
        setSliderPosLeft(resSlidePos);
      }
    }
  };

  return (
    <div className={classes.slider}>
      <div className={classes.sliderBody}>
        <div
          className={[classes.sliderContainer, "container"].join(" ")}
          ref={containerRef}
        >
          <div className={[classes.sliderTitle, "font-48"].join(" ")}>
            {t('here_you_will_title')}
          </div>
          <div
            ref={refPassthrough}
            className={[classes.sliderSlider, "no-select"].join(" ")}
            {...handlers}
            style={{ left: sliderPosLeft + "px" }}
          >
            <div className={classes.sliderSliderColumn} ref={column1Ref}>
              <div className={classes.sliderSliderColumnItem}>
                <div className={classes.sliderSliderColumnItemIcon}>
                  <img src={img1} alt="" />
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemTitle,
                    "font-32",
                  ].join(" ")}
                >
                  {t('here_you_will_card_title_01')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemText,
                    "font-20",
                  ].join(" ")}
                >
                  {t('here_you_will_card_text_01')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemLink,
                    "font-18",
                  ].join(" ")}
                >
                  {t('here_you_will_card_btn_01')}
                  <img src={arrowOrange} alt="" />
                </div>
              </div>
            </div>
            <div className={classes.sliderSliderColumn}>
              <div className={classes.sliderSliderColumnItem}>
                <div className={classes.sliderSliderColumnItemIcon}>
                  <img src={img2} alt="" />
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemTitle,
                    "font-32",
                  ].join(" ")}
                >
                  {t('here_you_will_card_title_02')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemText,
                    "font-20",
                  ].join(" ")}
                >
                  {t('here_you_will_card_text_02')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemLink,
                    "font-18",
                  ].join(" ")}
                >
                  {t('here_you_will_card_btn_02')}
                  <img src={arrowOrange} alt="" />
                </div>
              </div>
            </div>
            <div className={classes.sliderSliderColumn}>
              <div className={classes.sliderSliderColumnItem}>
                <div className={classes.sliderSliderColumnItemIcon}>
                  <img src={img3} alt="" />
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemTitle,
                    "font-32",
                  ].join(" ")}
                >
                  {t('here_you_will_card_title_03')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemText,
                    "font-20",
                  ].join(" ")}
                >
                  {t('here_you_will_card_text_03')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemLink,
                    "font-18",
                  ].join(" ")}
                >
                  {t('here_you_will_card_btn_03')}
                  <img src={arrowOrange} alt="" />
                </div>
              </div>
            </div>
            <div className={classes.sliderSliderColumn}>
              <div className={classes.sliderSliderColumnItem}>
                <div className={classes.sliderSliderColumnItemIcon}>
                  <img src={img4} alt="" />
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemTitle,
                    "font-32",
                  ].join(" ")}
                >
                  {t('here_you_will_card_title_04')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemText,
                    "font-20",
                  ].join(" ")}
                >
                  {t('here_you_will_card_text_04')}
                </div>
                <div
                  className={[
                    classes.sliderSliderColumnItemLink,
                    "font-18",
                  ].join(" ")}
                >
                  {t('here_you_will_card_btn_04')}
                  <img src={arrowOrange} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.eightSlider} id="myBeginSlider">
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{ clickable: true, el: paginationRef.current }}
              spaceBetween={20}
              slidesPerView={1}
              loop={true}
            >
              <SwiperSlide>
                <div className={classes.sliderSliderColumn}>
                  <div className={classes.sliderSliderColumnItem}>
                    <div className={classes.sliderSliderColumnItemIcon}>
                      <img src={img1} alt="" />
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemTitle,
                        "font-32",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_title_01')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemText,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_text_01')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemLink,
                        "font-18",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_btn_01')}
                      <img src={arrowOrange} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={classes.sliderSliderColumn}>
                  <div className={classes.sliderSliderColumnItem}>
                    <div className={classes.sliderSliderColumnItemIcon}>
                      <img src={img2} alt="" />
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemTitle,
                        "font-32",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_title_02')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemText,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_text_02')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemLink,
                        "font-18",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_btn_02')}
                      <img src={arrowOrange} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={classes.sliderSliderColumn}>
                  <div className={classes.sliderSliderColumnItem}>
                    <div className={classes.sliderSliderColumnItemIcon}>
                      <img src={img3} alt="" />
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemTitle,
                        "font-32",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_title_03')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemText,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_text_03')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemLink,
                        "font-18",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_btn_03')}
                      <img src={arrowOrange} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={classes.sliderSliderColumn}>
                  <div className={classes.sliderSliderColumnItem}>
                    <div className={classes.sliderSliderColumnItemIcon}>
                      <img src={img4} alt="" />
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemTitle,
                        "font-32",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_title_04')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemText,
                        "font-20",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_text_04')}
                    </div>
                    <div
                      className={[
                        classes.sliderSliderColumnItemLink,
                        "font-18",
                      ].join(" ")}
                    >
                      {t('here_you_will_card_btn_04')}
                      <img src={arrowOrange} alt="" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="swiper-pagination1" ref={paginationRef}></div>
          <div className={classes.sliderBottom}>
            <div className={classes.sliderBottomLeft}>
              <div
                className={[classes.sliderBottomLeftText, "font-24"].join(" ")}
              >
                {t('here_you_will_text')}
              </div>
              <div className={classes.sliderBottomBtn}>
                <Button>{t('btn_begin_trading')}</Button>
              </div>
            </div>
            <div className={classes.sliderBottomRight}>
              <div className={classes.sliderBottomRightArrows}>
                <div
                  className={
                    sliderPosLeft === 0
                      ? [
                          classes.sliderBottomRightArrowsLeft,
                          classes.disabled,
                        ].join(" ")
                      : classes.sliderBottomRightArrowsLeft
                  }
                  onClick={slideLeft}
                ></div>
                {window.innerWidth > 1420 && (
                  <div
                    className={
                      countSlide === 2
                        ? [
                            classes.sliderBottomRightArrowsRight,
                            classes.disabled,
                          ].join(" ")
                        : classes.sliderBottomRightArrowsRight
                    }
                    onClick={slideRight}
                  ></div>
                )}
                {window.innerWidth < 1421 && (
                  <div
                    className={
                      countSlide === 3
                        ? [
                            classes.sliderBottomRightArrowsRight,
                            classes.disabled,
                          ].join(" ")
                        : classes.sliderBottomRightArrowsRight
                    }
                    onClick={slideRight}
                  ></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
