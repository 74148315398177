import React from "react";
import classes from "./BigestProfit.module.scss";
import img1 from "../../../assets/img/AboutUsPage/bigest-profit-img.png";
import { useTranslation } from "react-i18next"; 

const BigestProfit = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.bigestProfitContainer, "container"].join(" ")}>
            <div className={classes.bigestProfitWrap}>
                <div className={classes.bigestProfitTextWrap}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        <span>
                            {t('about_bigest_profit_title_01')}
                        </span>
                        <span>
                            {t('about_bigest_profit_title_02')}
                        </span>
                        <span>
                            {t('about_bigest_profit_title_03')}
                        </span>
                    </h2>
                    <p className={[classes.bigestProfitText, 'font-24'].join(" ")}>
                        {t('about_bigest_profit_text')}
                    </p>
                </div>
                <div className={classes.bigestProfitImg}>
                    <img src={img1} className={classes.imgFluid} alt="Are You Ready to Get the Biggest Profit Now?" />
                </div>
            </div>
        </div>
    )
}

export default BigestProfit;

