import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import Header from './components/GlobalComponents/Header/Header';
import Footer from './components/GlobalComponents/Footer/Footer';
import './App.scss';
import { images } from "./utils/imagesToLoad";
import $ from 'jquery';

function App() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ imagesReady, setImagesReady ] = useState(false);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    if (isLoading) {
      $('body').addClass("ovf-hidden");
    }
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setImagesReady(true))
      .catch((err) => console.log("Failed to load images", err));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (imagesReady) {
      setTimeout(() => {
        setIsLoading(false);
      }, [ 1000 ]);
    }
  }, [ imagesReady ]);
  useEffect(() => {
    if (!isLoading) {
      $('body').removeClass("ovf-hidden");
    }
  }, [ isLoading ]);
  return (
    <div >
      <BrowserRouter>
      <div className={`preloader ${!isLoading && 'hidePreloader'}`}>
        <span className="loader"></span>
      </div> 
      {imagesReady &&
        <>
          <Header />
          <Router />
          <Footer />
        </>
      }
      </BrowserRouter>
    </div>
  );
}

export default App;
