import React from "react";
import classes from "./Footer.module.scss";
import { Link } from "react-router-dom";
import logo from '../../../assets/img/Global/logo.png';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={classes.footer}>
      <div className={["container", classes.container].join(' ')}>
        <div className={classes.header}>
          <div className={classes.links}>
            <div className={classes.logo}>
              <Link to='/'>
                <img className="no-select" src={logo} alt=''/>
              </Link>
            </div>
            <div className={classes.pages}>
              <div><Link to='/begin'>{t('header_begin_now')}</Link></div>
              <div><Link to='/trading'>{t('header_trading_space')}</Link></div>
              <div><Link to='/education'>{t('header_education')}</Link></div>
              <div><Link to='/about'>{t('header_about_us')}</Link></div>
            </div>
            <div className={classes.docs}>
              <div>
                <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                  {t('footer_terms_conditions')}
                </a>
              </div>
              <div>
                <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                  {t('footer_privacy_policy')}
                </a>
              </div>
              <div>
                <a href="/docs/AML_POLICY.pdf" target="_blank">
                  {t('footer_aml_policy')}
                </a>
              </div>
              <div>
                <a href="/docs/LEGAL_DOCUMENTS.pdf" target="_blank">
                  {t('footer_legal_docs')}
                </a>
              </div>
            </div>
          </div>
          <div className={classes.info}>
            <div className={classes.contactUs}>
              <div>{t('footer_contact_us')}</div>
              <div><a href="tel:+441515281485">+441515281485</a></div>
              <div><a href={`mailto:support@${hostname}`}>support@{hostname}</a></div>
              <div>12 Lakeview Terrace, Auckland 1024, New Zealand</div>
            </div>
            {/* <div className={classes.mails}>
              <div>{t('footer_rights')}</div>
              <div>
                {t('footer_general_info')}
                <a href="mailto:info@Lloyds Markets.com">info@Lloyds Markets.com</a>
              </div>
              <div>
                {t('footer_tech_support')}
                <a href="mailto:support@Lloyds Markets.com">support@Lloyds Markets.com</a>
              </div>
            </div> */}
          </div>
        </div>
        <div className={classes.bottom}>
            <div className={classes.aboutUs}>
              <div className={classes.bottomTitle}>
                {t('footer_about_us')}
              </div>
              <div className={classes.bottomText}>
                {t('footer_about_us_text')}
              </div>
            </div>
            <div className={classes.disclaimer}>
              <div className={classes.disclaimerText}>
                <div className={classes.bottomTitle}>
                  {t('footer_disclaimer')}
                </div>
                <div className={classes.bottomText}>
                  {t('footer_disclaimer_text')}
                </div>
              </div>
              <div className={classes.rights}>
                {t('footer_rights')}
              </div>
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
