import React, { useRef, useEffect } from "react";
import classes from "./Newsletters.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import img1 from "../../../assets/img/BeginPage/5/1.png";
import { useTranslation } from "react-i18next";

const Newsletters = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const firstRef = useRef(null);
  const objRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 1220) {
      let lineAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top 1000",
          end: "center 600",
          scrub: true,
        },
      });
      lineAnimation.to(
        objRef.current,

        { left: 0 }
      );
    }
  }, []);

  return (
    <div className={classes.trading} ref={firstRef}>
      <div className={classes.tradingBody}>
        <div className="container">
          <div className={classes.tradingRow}>
            <div className={classes.tradingRowRight} ref={objRef}>
              <div className={classes.tradingRowRightImg}>
                <img src={img1} alt="" />
              </div>
            </div>
            <div className={classes.tradingRowLeft}>
              <div
                className={[classes.tradingRowLeftTitle, "font-64"].join(" ")}
              >
                {t('stock_markets_title')}
              </div>

              <div
                className={[classes.tradingRowLeftText, "font-24"].join(" ")}
              >
                {t('stock_markets_text')}
              </div>
              <div className={classes.tradingRowLeftList}>
                <div
                  className={[classes.tradingRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t('stock_markets_li_01')}
                </div>
                <div
                  className={[classes.tradingRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t('stock_markets_li_02')}
                </div>
                <div
                  className={[classes.tradingRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t('stock_markets_li_03')}
                </div>
                <div
                  className={[classes.tradingRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t('stock_markets_li_04')}
                </div>
                <div
                  className={[classes.tradingRowLeftListItem, "font-24"].join(
                    " "
                  )}
                >
                  {t('stock_markets_li_05')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletters;
